import React, { useEffect, useRef } from "react";
import { Col, Row } from "antd";
import Link from "next/link";
import Image from "next/image";
import Typography from "../../../components/Typography";
import { Container, ImageContainer, ItemContainer } from "./styled";

export default function ProductsAndServicesGrid({ items, colWidth }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.querySelectorAll('.fade-in-up').forEach(item => {
              item.classList.add('visible');
            });
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <Row gutter={[32, 32]}>
        {items.map((item) => (
          <Col
            key={item.id}
            xs={24}
            sm={12}
            md={colWidth}
            className="fade-in-up"
          >
            <Link href={item.image.href} passHref>
              <ItemContainer>
                <ImageContainer>
                  <Image
                    src={item.image.src}
                    alt={item.image.alt}
                    width={item.image.width}
                    height={item.image.height}
                    layout="responsive"
                    quality={70}
                  />
                </ImageContainer>
                <Typography
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="1.2rem"
                  margin={[16, 0, 8, 0]}
                  fontFamily="Roboto Bold"
                >
                  {item.title}
                </Typography>
                <Typography textAlign="center" fontSize="1rem">
                  {item.content}
                </Typography>
              </ItemContainer>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
