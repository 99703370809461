import useMobile from "../../../hooks/useMobile";
import ProductsAndServices from "../../ProductsAndServices";
import pageData from "../data.json";

export default function ProductsAndServicesSection() {
  const isMobile = useMobile();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true
  };

  return (
    <ProductsAndServices
      items={pageData["products-and-services"]}
      colWidth={24 / 4}
      variant={isMobile ? "slider" : "grid"}
      settings={settings}
    />
  );
}
