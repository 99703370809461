import React from "react";
import Slider from "react-slick";
import ProductAndService from "../ProductAndService";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "../styled";

export default function ProductsAndServicesSlider({ items, settings }) {
  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
    ...settings
  };

  return (
    <Container>
      <Slider {...defaultSettings}>
        {items.map((item) => (
          <ProductAndService key={item.id} {...item} />
        ))}
      </Slider>
    </Container>
  );
}
