import React from "react";
import Typography from "../../components/Typography";
import ProductsAndServicesGrid from "./Grid";
import ProductsAndServicesSlider from "./Slider";
import { Container, FooterButton } from "./styled";
import Link from "next/link";

export default function ProductsAndServices({
  items,
  header,
  footer,
  colWidth,
  variant,
  settings
}) {
  return (
    <Container>
      {header || (
        <Typography
          textAlign="center"
          // fontWeight="bold"
          fontSize="2rem"
          fontFamily="Lora Bold"
          color="#762738"
          margin={[0, 0, 32, 0]}
        >
          Our Products & Services
        </Typography>
      )}
      {variant === "slider" && <ProductsAndServicesSlider items={items} settings={settings} />}
      {variant !== "slider" && (
        <ProductsAndServicesGrid items={items} colWidth={colWidth} />
      )}
      {footer || (
        <Link passHref href="/view-all-products">
          <FooterButton variant="outline">ALL CLASSY PRODUCTS</FooterButton>
        </Link>
      )}
    </Container>
  );
}
